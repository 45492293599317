<template>
   <section class="cont preSaleDetails">
      <!-- 面包屑 -->
      <el-row class="crumbs-box">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>业务中台</el-breadcrumb-item>
            <el-breadcrumb-item>预售券</el-breadcrumb-item>
            <el-breadcrumb-item>预售券明细报表</el-breadcrumb-item>
         </el-breadcrumb>
      </el-row>
      <el-row class="content-box">
         <!-- 查询框 -->
         <el-row class="search-box">
            <el-row class="search-row">
               <el-row class="search-item">
                  <label>订单号：</label>
                  <el-input  class="width-180"  placeholder="请输入订单号" v-model="orderNo" clearable></el-input>
               </el-row>
               <el-row class="search-item">
                  <label>预售券名：</label>
                  <el-input  class="width-180"  placeholder="请输入预售券名" v-model="preSaleName" clearable></el-input>
               </el-row>
               <el-row class="search-item">
                  <label>手机号：</label>
                  <el-input  class="width-180"  placeholder="请输入手机号" v-model="mobile" clearable></el-input>
               </el-row>
               <el-row class="search-item">
                  <label>券码：</label>
                  <el-input  class="width-180"  placeholder="请输入券码" v-model="couponCode" clearable></el-input>
               </el-row>
            </el-row>

            <el-row class="search-row">
               <el-row class="search-item">
                  <label>下单时间：</label>
                  <el-date-picker
                        class="width-200"
                        v-model="beginTime"
                        type="datetime"
                        placeholder="请选择日期"
                        value-format="yyyy-MM-dd HH:mm:ss">
                  </el-date-picker>
                  至
                  <el-date-picker
                        class="width-200"
                        v-model="endTime"
                        type="datetime"
                        placeholder="请选择日期"
                        value-format="yyyy-MM-dd HH:mm:ss">
                  </el-date-picker>
               </el-row>
               <el-row class="search-item">
                  <label>购买渠道：</label>
                  <el-select class="width-180"  v-model="orderSource"  placeholder="请选择订单渠道" clearable>
                     <el-option
                           v-for="(item,index) in dictData['order-source']"
                           :key="index"
                           :label="item"
                           :value="index">
                     </el-option>
                  </el-select>
               </el-row>
               <el-row class="search-item">
                  <label>状态：</label>
                  <el-select class="width-180"  v-model="status"  placeholder="请选择状态" clearable>
                     <el-option
                           v-for="(item,index) in dictData['preSale-state']"
                           :key="index"
                           :label="item"
                           :value="index">
                     </el-option>
                  </el-select>
               </el-row>
               <el-button class="bg-gradient" type="primary"  @click="searchEvent">搜索</el-button>
               <el-button  type="primary" plain @click="handleReset">重置</el-button>
               <el-button  type="primary" plain @click="exportEvent" >导出</el-button>
            </el-row>
         </el-row>
         <!-- 主体内容  -->
         <el-row class="table-box">
            <el-table
                  v-loading="loading"
                  :data="tableData"
                  border fit
                  style="width: 100%"
                  :stripe="true"
                  :header-cell-style="{background: '#f5f8f9'}">
               <el-table-column label="序号"  width="60" type="index"></el-table-column>
               <el-table-column label="订单编号" min-width="85">
                  <template slot-scope="scope">
                     <span style="overflow:hidden;white-space:nowrap;text-overflow:ellipsis;">{{scope.row.orderNo }}</span>
                  </template>
               </el-table-column>
               <el-table-column label="预售券名称" prop="mainTitle"></el-table-column>
               <el-table-column label="预售券券码" prop="couponCode"></el-table-column>
               <el-table-column label="下单时间" prop="buyingTime"></el-table-column>
               <el-table-column label="手机号" prop="mobile"  oninput="value=value.replace(/[^\d]/g,'')" :maxlength="11"></el-table-column>
               <el-table-column label="购买渠道">
                  <template slot-scope="scope">{{ scope.row.orderSource || scope.row.receiveChannel | filterOrderSource(that)}} </template>
               </el-table-column>
               <el-table-column label="价格">
                  <template slot-scope="scope">
                     {{scope.row.presellPrice/100}}
                  </template>
               </el-table-column>
              <el-table-column label="核销时间" min-width="100">
                <template slot-scope="scope">
                  {{scope.row.useTime || '-'}}
                </template>
              </el-table-column>
               <el-table-column label="状态" prop="state">
                  <template slot-scope="scope">
                     {{scope.row.state | filterstate(that)}}
                  </template>
               </el-table-column>
               <el-table-column label="操作">
                  <template slot-scope="scope">
                     <el-button type="text" @click="operateEvent('check',scope.row)">
                        <el-link type="primary">详情</el-link>
                     </el-button>
                     <el-button type="text" @click="operateEvent('writeOff',scope.row)" v-if="scope.row.state === 'NOT_USED' && scope.row.orderStatus !== 'PENDING_PAYMENT'">
                        <el-link type="primary">核销</el-link>
                     </el-button>
                  </template>
               </el-table-column>
            </el-table>
            <pagination :total="total" :page-size="limit" @handleSizeChangeSub="pageChange" @handleCurrentChangeSub="handlePaging"/>
         </el-row>
      </el-row>
      <!-- 详情弹窗 -->
      <el-dialog class="detail-dialog-box" title="详情" :visible.sync="visibleDetail" width="660px">
         <el-row class="title t-a-left">
            <h6>订单详情</h6>
         </el-row>
         <el-row class="text-list">
            <el-row>
               <el-col :span="12">
                  <label class="details_label">订单编号：</label>
                  <span>{{detailsData.orderNo}}</span>

               </el-col>
               <el-col :span="12">
                  <label class="details_label">联系电话：</label>
                  <span>{{detailsData.mobile}}</span>
               </el-col>
            </el-row>
            <el-row>
               <el-col :span="12">
                  <label class="details_label">下单时间：</label>
                  <span>{{detailsData.buyingTime}}</span>
               </el-col>
               <el-col :span="12">
                  <label class="details_label">失效时间：</label>
                  <span>{{detailsData.activityEndTime}}</span>
               </el-col>
            </el-row>
            <el-row>
               <el-col :span="12">
                  <label class="details_label">订单来源：</label>
                  <span>{{detailsData.orderSource | filterOrderSource(that)}}</span>
               </el-col>
               <el-col :span="12">
                  <label class="details_label">金额：</label>
                  <span>{{detailsData.presellPrice/100}}元</span>
               </el-col>
            </el-row>
            <el-row>
               <el-col :span="12">
                  <label class="details_label">状态：</label>
                  <span>{{ detailsData.state | filterstate(that) }}</span>
               </el-col>
               <el-col :span="12">
                  <label class="details_label">核销时间：</label>
                  <span>{{ detailsData.useTime || '-' }}</span>
               </el-col>
            </el-row>
         </el-row>
         <el-row class="title m-top-50 t-a-left">
            <h6>预售券详情</h6>
         </el-row>
         <el-row class="text-list">
            <el-row>
               <el-col :span="12">
                  <label class="details_label">预售券名：</label>
                  <span>{{detailsData.mainTitle}}</span>
               </el-col>
               <el-col :span="12">
                  <label class="details_label">预售券简称：</label>
                  <span>{{ detailsData.subTitle }}</span>
               </el-col>
            </el-row>
            <el-row>
               <el-col :span="12">
                  <label class="details_label">预售券券码：</label>
                  <span>{{detailsData.couponCode}}</span>
               </el-col>
               <el-col :span="12">
                  <label class="details_label">限购数量：</label>
                  <span>{{ detailsData.limitQuantity }}份</span>
               </el-col>
            </el-row>
            <el-row>
               <el-col :span="12">
                  <label class="details_label">开始时间：</label>
                  <span>{{detailsData.activityBeginTime}}</span>
               </el-col>
               <el-col :span="12">
                  <label class="details_label">结束时间：</label>
                  <span>{{detailsData.activityEndTime}}</span>
               </el-col>
            </el-row>
            <el-row>
               <el-col :span="12">
                  <label class="details_label">过期退款方式：</label>
                  <span>{{ detailsData.refundType | refundType }}</span>
               </el-col>
               <el-col :span="12">
                  <label class="details_label">使用方式：</label>
                  <span>{{detailsData.useType | useType }}</span>
               </el-col>
            </el-row>
         </el-row>
         <div slot="footer" class="dialog-footer" >
            <el-button class="bg-gradient" type="primary" @click="visibleDetail = false" >取消</el-button>
         </div>
      </el-dialog>
   </section>
</template>

<script>
import { mapState } from 'vuex';
import {urlObj} from '@/api/interface'
import {preSale} from "@/api/interface/business"; // 公共js
import {  exportFile, getDict } from '@/common/js/common'
export default {
   name: "preSaleDetails",
   data(){
      return{
         that: this,
         orderNo:'',       // 订单号
         orderSource:"",    // 订单渠道
         preSaleName:"",   // 预售券名
         mobile:"",        // 电话
         couponCode:"",    // 券码
         beginTime:"",     // 下单开始时间
         endTime:"",       // 下单结束时间
         status:"",        // 状态

         loading:true,     // 表格加载效果
         tableData:[],     // 表格数据

         total: 0,        // 总条目数
         page: 1,         // 当前页 默认第一页
         limit: 0,        // 每页显示数

         visibleDetail:false,// 弹窗是否显示
         detailsData:{},      //详情数据
         // 提示文本
         writeOff:"确定进行核销吗?"
      }
   },
   computed: {
      ...mapState(['hotelInfo', 'dictData']),
   },
   watch: {
      hotelInfo: {
         handler(newVal, oldVal) {
            if (newVal.id !== oldVal.id && oldVal) {
               this.getList()
            }
         },
         deep: true
      }
   },
   mounted() {
      getDict(['preSale-state','order-source'],)
      this.limit = sessionStorage.getItem('pageSize') * 1
      this.getList()
   },
   methods:{
      //获取表格数据
      getList(){
         const url = preSale.couponMyselfPage + `?limit=${this.limit}&page=${this.page}`
         let param = {
            hotelId:this.hotelInfo.id,
            'orderDTO.orderNo':this.orderNo,
            "orderDTO.orderSource":this.orderSource,
            mainTitle:this.preSaleName, // 预售券名
            Mobile:this.mobile,         // 手机号
            couponCode:this.couponCode, // 券码

            beginTime:this.beginTime,   // 开始时间
            endTime:this.endTime,       // 结束时间
            state:this.status,           // 状态
         }
         this.$axios.post(url,param).then(res => {
            if (res.success) {
               this.total = res.total
               this.tableData = res.records
               this.loading = false
            }
         })
      },
      //搜索
      searchEvent(){
         this.getList()
      },
      //重置
      handleReset(){
         this.orderNo = ''
         this.preSaleName = ''
         this.mobile = ''
         this.couponCode = ''
         this.beginTime = ''
         this.endTime = ''
         this.orderSource = ''
         this.status = ''
      },
      //导出
      exportEvent(){
         const url = preSale.exportCouponMyselfList
         let param = {
            hotelId:this.hotelInfo.id,
            'orderDTO.orderNo':this.orderNo,
            "orderDTO.orderSource":this.orderSource,
            mainTitle:this.preSaleName, // 预售券名
            Mobile:this.mobile,         // 手机号
            couponCode:this.couponCode, // 券码
            beginTime:this.beginTime,   // 开始时间
            endTime:this.endTime,       // 结束时间
            state:this.status,           // 状态
         }
         this.$axios.get(url, param, {}, 'blob').then(res => {
            exportFile(res,'预售券报表明细')
         })
      },
      // 详情/核销
      operateEvent(status,row){
         //详情
         if(status === 'check'){
            this.visibleDetail = true
            this.detailsData = row
         }else{
            this.$confirm(this.writeOff, "提醒", {
               confirmButtonText: this.confirm,
               cancelButtonText: this.cancel,
               type: 'warning'
            }).then(() => {
               const url = preSale.useCouponMyself
               let param = {
                  state:'USED',
                  id:row.id,
                  couponId:row.couponId
               }
               this.$axios.post(url, param, 'json').then(res => {
                  if (res.status === 200){
                     this.$message({
                        showClose: true,
                        message: '活动核销成功！',
                        type: 'success'
                     })
                     this.getList()
                  }
               })
            })
         }
      },
      // 改变每页数
      pageChange(num) {
         this.limit = num
         this.getList()
      },
      // 改变当前页
      handlePaging(num) {
         this.page = num
         this.getList()
      },
   },
   filters: {
      // 状态
      filterstate(val,that) {
         if (val && that.dictData['preSale-state']) return that.dictData['preSale-state'][val] || val
      },
      //过期退款方式
      refundType(val){
         if (val === 'AUTOMATIC') return '自动退款'
         if (val === 'MANUAL') return '手动退款'
         if (val === 'REFUSE') return '不可退款'
      },
      //使用方式
      useType(val){
         if (val === 'ONLINE') return '线上预约'
         if (val === 'OFFLINE')  return '线下使用'
         if (val === 'ONLINE_OFFLINE') return '线上线下都可使用'
      },
      filterOrderSource(val,that){
         if (val && that.dictData['order-source']) return that.dictData['order-source'][val] || val
      }
   },
}
</script>

<style scoped lang="scss">
   .preSaleDetails{
      ::v-deep .details_label{
         display: inline-block;
         width: 100px;
         text-align: right;
      }
   }
</style>
